<template>
  <LandingPage/>
</template>

<script>
import LandingPage from './components/LandingPage.vue'

export default {
  name: 'App',
  components: {
    LandingPage
  }
}
</script>

<style lang="scss">
  :root {
    --html-background: #515254;
    --base-color: #4f4f50;
    --accent-color: #39393b;

    --white: #e7e8ea;
    --black: black;

    --scrollbar-color: lightgray;
    --scrollbar-color-hover: darkgray;
  }

  #app {
    width: 100%;
    height: 100%;
    font-family: 'Work Sans', 'Segoe UI Light', Avenir, Helvetica, Arial, sans-serif;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: var(--html-background);
  }

  /* scollbar */
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--scrollbar-color);
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-color-hover);
  }
</style>
