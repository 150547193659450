<template>
  <transition name="modal">
    <div class="containerModalBackground" v-if="modal" @click.self="closeModal()">
      <div class="containerModal">
        <div class="modalHead">
          <div class="modalTitle">
            {{ modalTitle }}
          </div>
          <div class="modalClose">
            <button @click="closeModal()"><font-awesome-icon icon="xmark" /></button>
          </div>
        </div>
        <div class="modalBody modalBodyImprint" v-if="modalType === 'Imprint'">
          imprint
        </div>
        <div class="modalBody modalBodySetting" v-if="modalType === 'Setting'">
          setting
        </div>
        <div class="modalBody modalBodyItem" v-if="modalType === 'Item'">
          item
        </div>
      </div>
    </div>
  </transition>

  <div class="wrapper">
    <div class="containerHeader">
        <div class="containerHeaderLogo">
          <img src="../../public/logo.png" alt="Logo" @click="openMenu()" />
        </div>
        <div class="containerHeaderTitle">
          <h1 @click="reloadPage()">ForeArtz.dev</h1>
        </div>
        <div class="containerHeaderImprint">
          <h1 @click="openModal('Imprint')">Impressum</h1>
        </div>
    </div>

    <div class="containerContent">
      <div class="containerItem" v-for="item in items" @click="openModal('Item', item.ID)">
        <div class="containerItemTitle">
          {{ item.name }}
        </div>
        <div class="containerItemPicture">
          <img alt="{{item.picAlt}}" :src="item.picture">
        </div>
      </div>

      <div class="containerItem" v-if="login" @click="openModal('Setting')">
        <div class="containerItemTitle">
          Einstellungen
        </div>
        <div class="containerItemPicture">
          <font-awesome-icon icon="cog" size="9x" />
        </div>
      </div>
    </div>

    <div class="containerFooter"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      login: true,
      items: [{ID : 1, name : 'Crochet', description : "simple crochet loop counter", link : "https://crochet.foreartz.dev", picture : "https://crochet.foreartz.dev/favicon.ico", picAlt : "Logo of the crochet counter"}, {ID : 2, name : "foreartz.dev", description : "Main website", link : "https://foreartz.dev", picture : "http://localhost:8080/logo.png", picAlt : "Logo of the main website"}],
      modal: false,
      modalType: "",
      modalTitle: "",
      modalData: {}
    }
  },
  name: "LandingPage",
  methods: {
      reloadPage: () => {
        location.reload();
      },
    openMenu: () => {
        alert("menu opened");
      },
    openModal: function (type, data = 0) {
        this.modal = true;
        this.isModalOpen();
        if(type === "Imprint"){
          this.modalTitle = "Impressum";
          this.modalType = "Imprint";
        }
        else if(type === "Setting"){
          this.modalTitle = "Einstellungen";
          this.modalType = "Setting";
        }
        else if(type === "Item"){
          this.modalType = "Item";
          for (let i = 0; i < this.items.length; i++) {
            if(this.items[i].ID === data){
              this.modalData = this.items[i];
            }
          }
          this.modalTitle = this.modalData.name;
        }
      },
    isModalOpen: function() {
      if(this.modal === true){
        document.querySelector("body").style.overflow = "hidden";
      }
      else{
        document.querySelector("body").style.overflow = "auto";
      }
    },
    closeModal: function() {
      this.modal = false;
      this.modalTitle= "";
      this.modalData = null;
      this.modalType = "";
      this.isModalOpen();
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrapper {
    width: 100%;
    min-width: 380px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1;
  }

  /* Modal */
  .containerModalBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
  }

  .containerModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    background-color: var(--accent-color);
    border-radius: 20px;
    z-index: 101;
    display: grid;
    grid-template-rows: 10% 90%;
    grid-template-columns: 100%;
  }

  .modalHead{
    display: grid;
    grid-row: 1;
    grid-column: 1;
    grid-template-rows: 100%;
    grid-template-columns: 90% 10%;
    color: var(--white);
  }

  .modalTitle {
    padding-left: 20px;
    grid-row: 1;
    grid-column: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 100;
    font-size: 2rem;
  }

  .modalClose {
    padding-right: 20px;
    display: flex;
    grid-row: 1;
    grid-column: 2;
    align-items: center;
    justify-content: flex-end;
  }

  .modalClose button {
    background-color: transparent;
    border: none;
    color: var(--white);
    font-size: 1.3rem;
    font-weight: 100;
    cursor: pointer;
    transition: 0.1s;
  }

  .modalClose button:hover {
    color: var(--accent-color);
    background-color: var(--white);
    border-radius: 5px;
  }

  .modalBody{
    color: var(--white);
  }

  .modalBodyImprint{
    grid-row: 2;
    grid-column: 1;
  }

  .modalBodyItem{
    grid-row: 2;
    grid-column: 1;
  }

  .modalBodySetting{
    grid-row: 2;
    grid-column: 1;
  }

  /* Header */
  .containerHeader {
    width: 100%;
    height: 10vh;
    background-color: var(--accent-color);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0 30px;
    color: var(--white);
    box-shadow: 0 0 10px #161616;
  }

  .containerHeaderLogo {
    width: 10%;
    max-width: 100px;
    min-width: 50px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }

  .containerHeaderLogo img {
    width: auto;
    height: 50%;
    transition: 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .containerHeaderTitle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }

  .containerHeaderTitle h1 {
    font-size: 2rem;
    font-weight: 100;
    transition: 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

.containerHeaderImprint {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }

  .containerHeaderImprint h1 {
    font-size: 2rem;
    font-weight: 100;
    transition: 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .containerHeaderImprint h1:hover, .containerHeaderTitle h1:hover {
    font-size: 2.2rem;
  }

  .containerHeaderLogo img:hover {
    height: 60%;
  }

  /* Content */
  .containerContent {
    width: 100%;
    min-height: calc(90vh - 40px);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    justify-items: center;
    align-content: space-around;
    gap: 20px;
    padding: 20px 0;
  }

  .containerItem{
    width: 300px;
    height: 300px;
    background-color: var(--accent-color);
    border-radius: 20px;
    transition: 0.1s;
    user-select: none;
    box-shadow: 0 0 5px #262222;
    border: 1px solid #363636;
    cursor: pointer;
  }

  .containerItem:hover {
    transform: scale(1.025);
  }

  .containerItemTitle {
    width: 100%;
    height: 75px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: var(--white);
  }

  .containerItemPicture {
    width: 100%;
    height: 225px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--white);
  }

  .containerItemPicture img {
    width: auto;
    height: 150px;
  }

  /* Footer */
  .containerFooter {
    width: 100%;
    height: 5vh;
    display: none;
    background-color: var(--accent-color);
  }

  /* Transition */
  .modal-enter-from,
  .modal-leave-to {
    opacity: 0;
    transform: scale(1.1) translate(0px, 30px);
  }

  .modal-enter-active,
  .modal-leave-active {
    transition: 0.3s ease;
  }

  /* Media Queries */

  @media only screen and (max-width: 1450px) {
    .containerContent {
      grid-template-columns: repeat(3, 1fr);
    }
    @media only screen and (max-width: 1000px) {
      /* Content */
      .containerContent {
        grid-template-columns: repeat(2, 1fr);
      }

      .containerModal {
        width: 95%;
        height: 95%;
      }

      @media only screen and (max-width: 700px) {
        /* Content */
        .containerContent {
          grid-template-columns: repeat(1, 1fr);
        }

        @media only screen and (max-width: 500px) {
          /* Header */
          .containerHeaderTitle, .containerHeaderImprint, .containerHeaderLogo {
            width: calc(100% - 20px);
            padding: 0 10px;
          }

          .containerHeader {
            gap: 0;
          }

          .containerHeaderTitle h1 {
            font-size: 1.5rem;
          }

          .containerHeaderImprint h1 {
            font-size: 1.5rem;
          }

          .containerHeaderLogo {
            max-width: 10%;
          }

          .containerHeaderImprint h1:hover, .containerHeaderTitle h1:hover {
            font-size: 1.8rem;
          }
        }
      }
    }
  }
</style>
